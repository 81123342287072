export const testAccounts = [
  "mtest@momentumsolar.com",
  "mgiudice@momentumsolar.com",
  "jmahoney@momentumsolar.com",
  "arthur@momentumsolar.com",
  "alex@momentumsolar.com",
  "sung@momentumsolar.com",
  "jdeanna@momentumsolar.com",
  "dmorrissey@momentumsolar.com",
  "clantier@momentumsolar.com",
  "reptest@momentumsolar.com",
  "oney@momentumsolar.com",
  "sdillon@momentumsolar.com",
  "rslattery@momentumsolar.com",
  "rslatterytest@momentumsolar.com",
];
export default testAccounts;
