import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabListItem, ActiveTabListItem } from './styles';
import { Context } from '../../App';

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    // onClick: PropTypes.func.isRequired
  };

  onClick = userName => {
    const { label, onClick } = this.props;
    // console.log(clickLog);
    onClick(label, userName);
  };

  render() {
    const {
      props: { activeTab, label, tabArrayLength, reviewLevel },
      onClick,
    } = this;
    //console.log(this.props);
    const TabsContainer = activeTab === label ? ActiveTabListItem : TabListItem;

    return (
      <Context.Consumer>
        {({ userValues: { userName } }) => (
          <TabsContainer
            width={`calc(100% / ${tabArrayLength})`}
            label={label}
            reviewLevel={reviewLevel[1].props.incidentsWarning}
            onClick={() => onClick(userName)}
          >
            {label}
          </TabsContainer>
        )}
      </Context.Consumer>
    );
  }
}

export default Tab;
