import React, { useContext } from "react";
import { Loader } from "./styles";
import { Context } from "../../App";

const TableLoader = () => {
  const { show } = useContext(Context);
 // console.log(show);
  return (
    <Loader sideNavOpen={show}>
      <div className="loader" />
    </Loader>
  );
};

export default TableLoader;
