import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import { TabsContainer } from './styles';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  state = {
    activeTab: this.props.children[0].props.label,
  };

  onClickTabItem = tab => {
    // this.props.onClick(tab)
    this.setState({ activeTab: tab });
  };

  render() {
    // console.log(this.props);
    const {
      onClickTabItem,
      props: { children, width, nonCurved, flexTabContent, justify },
      state: { activeTab },
    } = this;
    // console.log(this.props);
    return (
      <TabsContainer
        justified={justify}
        width={width}
        nonCurved={nonCurved}
        flexTabContent={flexTabContent}
      >
        <ol>
          {children.map(child => {
            const { label } = child.props;

            return (
              <Tab
                reviewLevel={this.props.children}
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                tabArrayLength={this.props.children.length}
              />
            );
          })}
        </ol>
        <div className="tab-content">
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </TabsContainer>
    );
  }
}

export default Tabs;
