import React from "react";
import { ListViewContainer } from "./styles";

const ListView = ({ headings, rows }) => {
  return rows.map((row, index) => {
    return (
      <ListViewContainer key={index}>
        <div>
          <ul className="leftList">
            {headings.map((heading, index) => {
              return (
                <li className="leftItems" key={index}>
                  {heading}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className="rightList">
            {row.map((oneRow, index) => {
              return (
                <li className="rightItems" key={index}>
                  {oneRow}
                </li>
              );
            })}
          </ul>
        </div>
      </ListViewContainer>
    );
  });
};

export default ListView;
