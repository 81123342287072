import React, {useState} from "react";
//components
import Cell from "./Cell";
//styles
import { Row } from "./styles";
import moment from 'moment';
import RenderSingleRow from './RenderSingleRow'



const RowRender = ({ currentRows }) => {
  return currentRows.length ? (
    currentRows.map((currentRow, rowIndex) => {
      return (<RenderSingleRow key={`row-${rowIndex}`} currentRow={currentRow} rowIndex={rowIndex}/>)
    })
  ) : (
    <tr>
      <Cell nullCell={true} key="cell" content="No Current Data" />
    </tr>
  )};

export default RowRender;
