import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SelectIcon from "../SelectIcon/SelectIcon";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import AppButtonLaunch from "../AppButtonLaunch";
//constants

import logoFromHeader from "../../assets/CALLCENTERTOOLS3.png";
import { StyledHeader } from "./styles";
import { Context } from "../../App";

export const Header = (props) => {
  const userInfoCallback = (data) => {
    console.log(data);
  };

  const {
    show,
    setShow,
    userValues: { name },
  } = useContext(Context);
  return (
    <StyledHeader show={show ? "0deg" : "180deg"}>
      <AppBar className="navbar">
        <Toolbar className="toolbarLeft">
          <IconButton
            className="menuButton"
            aria-label="Menu"
            onClick={() => setShow(!show)}
          >
            <MenuIcon />
          </IconButton>
          <img
            className="imgHeader"
            src={logoFromHeader}
            alt="our-guest-logo"
          />
        </Toolbar>
        <Toolbar className="toolbar">
          {window.innerWidth > 600 && (
            <span className="grow">Momentum Solar</span>
          )}
          <p>{name}</p>
          <AppButtonLaunch
            className="logoutButton align-self-center"
            userInfoCallback={userInfoCallback}
          />
        </Toolbar>
      </AppBar>
    </StyledHeader>
  );
};
