import React, { useContext } from "react";
import {
  Router,
  Route,
  Switch,
  Link,
  NavLink,
  Redirect,
  withRouter
} from "react-router-dom";
import "./styles.css";
import createHistory from "history/createBrowserHistory";
import Login from "../components/Login";
import AdminDash from '../components/AdminDash'

import SideNav from "../components/SideNav/SideNav";

import { Header } from "../components/Header/Header";
import Dashboard from "../components/Dashboard";

import { Context } from "../App";

export const history = createHistory();

const PrivateRoute = withRouter(props => {
  const {
    show,
    userValues: { userName }
  } = useContext(Context);
  //console.log(userName);
  const { component: Component, ...rest } = props;
  return !userName ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            <Header header={history} />
            <SideNav />
            <div className={show ? "RouterMainOpen" : "RouterMainClosed"}>
              <Component {...props} />
            </div>
          </>
        );
      }}
    />
  );
});

// PrivateRoute = withRouter(PrivateRoute);

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={Login} exact={true} />
      <Route path="/emailhelp" component={Login} exact={true} />
      <PrivateRoute path="/dashboard" component={Dashboard} exact={true} />
      {/* {admins.includes(userName) && */}
      <PrivateRoute path="/adminView/:email" component={AdminDash} exact={true} />
      {/* } */}
    
    </Switch>
  </Router>
);

export default AppRouter;
