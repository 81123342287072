import React from "react";
import moment from "moment";

import { extendMoment } from "moment-range";

let today;
let priorDate;
let startOfMonth;
let filterActive;
let newRange;
let thisMonthRange;

startOfMonth = moment().startOf("month");

today = moment();

export const thisMonthFilter = appointmentData =>
  appointmentData.filter(singleData => {
    // console.log(singleData)
    priorDate = moment(singleData[2]);
    newRange = today.diff(priorDate, "days");
    thisMonthRange = today.diff(startOfMonth, "days");
    //console.log(newRange, thisMonthRange)
    return newRange <= thisMonthRange;
  });

export const lastMonthFilter = appointmentData => {
  let dateFrom = moment().startOf("month");
  return (filterActive = appointmentData.filter(singleData => {
    priorDate = moment(singleData[2]);

    let endOfLastMonthFilter = dateFrom.diff(priorDate, "day");

    return priorDate < dateFrom && endOfLastMonthFilter < 30;
  }));
};
