import React, { Component } from "react";
//styles
//icons
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from "@material-ui/icons";

import { PageNumberDiv } from "./styles";

class TableNav extends Component {
  render() {
    const {
      currentPage,
      handleFirstClick,
      handleArrowClick,
      pageNumbersState,
      rows,
      rowsTableOne,
      handlePageClick,
      search,
    } = this.props;

    let pageNumbers = [];

    for (
      let i = 1;
      i <=
      Math.ceil(!search ? rows.length / rowsTableOne : search / rowsTableOne);
      i++
    ) {
      pageNumbers.push(i);
    }

    let renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li
          className={
            currentPage === number ? "pageButtonsActive" : "pageButtons"
          }
          key={number}
          id={number}
          onClick={handlePageClick}
        >
          {number}
        </li>
      );
    });

    if (pageNumbers.length > 3 && currentPage === 1) {
      pageNumbers = [currentPage, currentPage + 1];
      renderPageNumbers = pageNumbers.map((number) => {
        return (
          <li
            className={
              currentPage === number ? "pageButtonsActive" : "pageButtons"
            }
            key={number}
            id={number}
            onClick={handlePageClick}
          >
            {number}
          </li>
        );
      });
    } else if (pageNumbers.length > 3) {
      renderPageNumbers = pageNumbers
        .slice(currentPage - 2, currentPage + 1)
        .map((number) => {
          return (
            <li
              className={
                currentPage === number ? "pageButtonsActive" : "pageButtons"
              }
              key={number}
              id={number}
              onClick={handlePageClick}
            >
              {number}
            </li>
          );
        });
    }

    return (
      <PageNumberDiv>
        <div className="pageNumbersLeftDiv">
          <FirstPage
            className={
              currentPage === 1 ? "chevronTableActive" : "chevronTable"
            }
            onClick={() => handleFirstClick("left")}
          />
          <ChevronLeft
            className="chevronTable"
            onClick={() => handleArrowClick("left")}
          />
        </div>
        <div className="pageNumbers">{renderPageNumbers}</div>
        <div className="pageNumbersRightDiv">
          <ChevronRight
            className="chevronTable"
            onClick={() => handleArrowClick("right")}
          />
          <LastPage
            className={
              currentPage === pageNumbersState.length
                ? "chevronTableActive"
                : "chevronTable"
            }
            onClick={() => handleFirstClick("right")}
          />
        </div>
      </PageNumberDiv>
    );
  }
}

export default TableNav;
