import React, { useState, useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "styled-components";

import AppRouter from "./routers/AppRouter";
const Context = React.createContext({});
export { Context };
const theme = {
  lightBlue: "#3cc7fa",
  midnightBlue: "#001649",
  lightSteelBlue: "#E3E9EE",
  slateGrey: "#6f7d8B",
  cadetBlue: "#658eb3",
  challengeGreen: "#49B54A",
  alertRed: "#FF0000",
  backgroundDarkGrey: "#f5f5f5",
  backgroundLightGrey: "#e5e5e5",
  font: "Roboto, sans-serif",
};

const App = () => {
  const [userValues, setValue] = useState({ name: "", userName: "" });
  const [show, setShow] = useState(true);
  const [appointmentData, setAppointmentData] = useState({
    appointmentData: "",
    callCenterRepEmail: "",
    callCenterRepName: "",
    allThirtyData: "",
    isQueryMade: true,
  });
  useEffect(() => {
    console.log("Version number 1.0.1");
  }, []);
  return (
    <Context.Provider
      value={{
        userValues,
        setValue,
        show,
        setShow,
        appointmentData,
        setAppointmentData,
      }}
    >
      <ThemeProvider theme={theme}>
        <div className="App-container">
          <AppRouter />
        </div>
      </ThemeProvider>
    </Context.Provider>
  );
};

export default App;
