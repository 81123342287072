import React, { useEffect, useContext } from "react";
import API from "../utils/API";
import testAccounts from "./testAccounts";
import "../css/Dashboard.css";
import Tabs from "./Tabs/Tabs";
import TableLoader from "./TableLoader/TableLoader";
import {
  noSaleFilter,
  futureDemoFilter,
  soldProjectsFilter,
  soldProjectsUninstalled,
  soldProjectsCancelled,
  all,
  past30,
  next30,
  allFilter,
} from "../filterUtils/Filter";
import Table from "./Table/Table";
import { Context } from "../App";
import { withRouter } from "react-router-dom";
import { CardNonTab } from "../css/styles";
import { Phone } from "@material-ui/icons";

let headingsArray = [
  ["Customer"],
  ["Set Date"],
  ["Demo Date"],
  ["Status"],
  ["Scheduling Status"],
];
let headingsArrayNoSale = [
  ["Customer"],
  ["Set Date"],
  ["Demo Date"],
  ["Status"],
  ["Appointment Outcome"],
  ["Sub Outcome"],
  ["Scheduling Status"],
];

let headingsArray30 = [
  ["Homeowner"],
  ["Start Time"],
  ["Scheduling Status"],
  ["Assigned Sales Rep"],
  ["Outcome Status"],
  ["Outcome Notes"],
  ["Proposal Status"],
  ["Scheduling Status"],
];
let headingsArrayCancelled = [
  ["Customer"],
  ["Set Date"],
  ["Demo Date"],
  ["Status"],
  ["Cancel Date"],
  ["Scheduling Status"],
];

const Dashboard = ({ history }) => {
  const {
    userValues: { userName, name },
    appointmentData,
    setAppointmentData,
  } = useContext(Context);

  const asyncFunc = async () => {
    let accountNameData;

    // We are evaluating one last condition and that is if testAccounts in state includes the same value we set to user... or the login from  reduxStroe => state
    if (testAccounts.includes(userName.toLowerCase())) {
      // accountNameData = await API.getAppAccount(userName);
      accountNameData = await API("login", userName);
    }

    const SalesData = await API(
      "demoSetter",
      accountNameData ? accountNameData.data.data[0].testAccount : userName
    );

    if (SalesData.data.data.includes("Connection Failed")) {
      console.log("error occurred");
      return false;
    }

    if (!SalesData.data.data.length) {
      console.log("error occurred");
      setAppointmentData({ isQueryMade: false });
      return false;
    }

    const thirtyDataRes = await API(
      "thirtyData",
      accountNameData ? accountNameData.data.data[0].testAccount : userName
    );

    setAppointmentData({
      callCenterRepName: accountNameData
        ? SalesData.data.data[0].demoSetterClean
        : name,
      callCenterRepEmail: accountNameData
        ? SalesData.data.data[0].testAccount
        : userName,
      appointmentData: SalesData.data.data,
      isQueryMade: false,
      allThirtyData: thirtyDataRes.data.data,
    });
  };
  // console.log(asyncFunc)
  useEffect(() => {
    // setRefetchFunc(callFunc)
    if (userName) {
      asyncFunc();
    }
  }, [userName]);

  return !userName ? (
    history.push("/")
  ) : (
    <div className="dashCon">
      {appointmentData.isQueryMade ? (
        <TableLoader />
      ) : !appointmentData.appointmentData ? (
        <div style={{ fontSize: 18 }}>
          No current data, please check back in a few minutes. Contact support
          if problem persists.
        </div>
      ) : (
        <>
          <Tabs>
            <div label="Future Demo">
              <Table
                rowSearched={0}
                headings={headingsArray}
                rows={futureDemoFilter(appointmentData.appointmentData)}
              />
            </div>
            <div label="No Sale">
              <Table
                monthFilters={true}
                rowSearched={0}
                headings={headingsArrayNoSale}
                rows={noSaleFilter(appointmentData.appointmentData)}
              />
            </div>
            {/* <div label="Sold Projects (Energized / Installed)">
                    <Table
                      monthFilters={true}
                      rowSearched={0}
                      headings={headingsArray}
                      rows={soldProjectsFilter(appointmentData.appointmentData)}
                    />
                  </div>
                  <div label="Sold Projects (Not Installed)">
                    <Table
                      monthFilters={true}
                      rowSearched={0}
                      headings={headingsArray}
                      rows={soldProjectsUninstalled(appointmentData.appointmentData)}
                    />
                  </div>

                  <div label="Sold Projects (Cancelled)">
                    <Table
                      monthFilters={true}
                      rowSearched={0}
                      headings={headingsArrayCancelled}
                      rows={soldProjectsCancelled(appointmentData.appointmentData)}
                    />
                  </div>
                   */}
            {/* <div label="Last 30 Days">
                    <Table
                      secondSearch={true}
                      rowSearched={0}
                      headings={headingsArray30}
                      rows={past30(appointmentData.allThirtyData)}
                    />
                  </div>
                  <div label="Next 30 Days">
                    <Table
                      secondSearch={true}
                      rowSearched={0}
                      headings={headingsArray30}
                      rows={next30(appointmentData.allThirtyData)}
                    />
                  </div> */}
          </Tabs>
          <CardNonTab>
            <span className="spanNonTab">
              <Phone alt="messageHeaderIcon" className="cardTitleIcon" />
              All Demo Setter Data
            </span>
            <Table
              rowSearched={0}
              headings={headingsArray}
              rows={allFilter(appointmentData.appointmentData)}
            />
          </CardNonTab>
        </>
      )}
    </div>
  );
};

export default withRouter(Dashboard);
