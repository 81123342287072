import moment from "moment";

// let formatDate = date => moment(date).format("MMMM Do YYYY");
// let sortFormateDate = date => moment(date).format("yymmdd");

const sortIt = (data) =>
  data.sort((a, b) => {
    //console.log(sortFormateDate(a[2]))
    return a[2] > b[2] ? -1 : 1;
  });
const sortItTwo = (data) =>
  data.sort((a, b) => {
    //console.log(sortFormateDate(a[2]))
    return a[1] > b[1] ? -1 : 1;
  });

const formatDate = (date) => {
  return window.innerWidth > 450 ? date : moment(date).format("M/D/YY h:mm A");
};

export const allFilter = (data) => {
  const thing = data.reduce((array, callCenter) => {
    array.push([
      callCenter.oppName || "n/a",
      callCenter.setDate ? formatDate(callCenter.setDate) : "n/a",
      callCenter.demoDate ? formatDate(callCenter.demoDate) : "n/a",
      callCenter.status || "n/a",
      callCenter.schedulingStatus || "n/a",
    ]);
    return array;
  }, []);
  // const sorted = thing.sort((a, b) => (a[2] > b[2] ? -1 : 1));
  return sortIt(thing);
};

export const noSaleFilter = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (
      callCenter.apptOutcome === "No Sale" ||
      callCenter.apptOutcome === "Internal No Show" ||
      callCenter.apptOutcome === "Failed Credit"
    ) {
      array.push([
        callCenter.oppName || "n/a",
        callCenter.setDate || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.status || "n/a",
        callCenter.apptOutcome || "n/a",
        callCenter.apptSuboutcome || "n/a",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);
  // const sorted = thing.sort((a, b) => (a[2] > b[2] ? -1 : 1));

  return sortIt(thing);
};

export const futureDemoFilter = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (callCenter.apptOutcome === "Future Demo") {
      array.push([
        callCenter.oppName || "n/a",
        callCenter.setDate || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.status || "n/a",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);

  return sortIt(thing);
};

export const soldProjectsFilter = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (
      callCenter.apptOutcome === "Signed" &&
      callCenter.panelsInstalledDate !== null
    ) {
      array.push([
        callCenter.oppName || "n/a",
        callCenter.setDate || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.status || "n/a",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);
  return sortIt(thing);
};

export const soldProjectsUninstalled = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (
      callCenter.apptOutcome === "Signed" &&
      callCenter.status !== "Retention" &&
      callCenter.status !== "Cancelled" &&
      callCenter.panelsInstalledDate === null
    ) {
      array.push([
        callCenter.oppName || "n/a",
        callCenter.setDate || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.status || "n/a",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);
  return sortIt(thing);
};

export const soldProjectsCancelled = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (
      callCenter.apptOutcome === "Signed" &&
      (callCenter.status === "Retention" || callCenter.status === "Cancelled")
    ) {
      array.push([
        callCenter.oppName || "n/a",
        callCenter.setDate || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.status || "n/a",
        callCenter.cancelDate || "n/a",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);
  return sortIt(thing);
};

export const past30 = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (callCenter.nextLastFlag === "last") {
      array.push([
        callCenter.subject || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.schedulingStatus || "n/a",
        callCenter.assignedSalesRep || "n/a",
        callCenter.outcomeStatus || "n/a",
        callCenter.outcomeNotes || "",
        callCenter.proposalStatus || "",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);
  return sortItTwo(thing);
};

export const next30 = (data) => {
  const thing = data.reduce((array, callCenter) => {
    if (callCenter.nextLastFlag === "next") {
      array.push([
        callCenter.subject || "n/a",
        callCenter.demoDate || "n/a",
        callCenter.schedulingStatus || "n/a",
        callCenter.assignedSalesRep || "n/a",
        callCenter.outcomeStatus || "n/a",
        callCenter.outcomeNotes || "",
        callCenter.proposalStatus || "",
        callCenter.schedulingStatus || "n/a",
      ]);
    }
    return array;
  }, []);
  return sortItTwo(thing);
};
