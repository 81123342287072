import React, { useState } from "react";
//styles
import { NullCell, CellHeader } from "./styles";
//icons
import {
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
  FilterList
} from "@material-ui/icons";
import DropdownDiv from "./DropdownDiv";
import styled from "styled-components";
export const StyledButton = styled.button`
  align-self: ${props => props.alignSelf};
  color: white;
  display: flex;
  padding: 1px;
  margin: 0 6px;
  justify-content: space-evenly;
  align-items: center;
  background-color: #001d49;
  border: none;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  visibility: ${props => props.displayVal};
`;

const Cell = ({
  content,
  header,
  handleSort,
  sortDirection,
  sorter,
  nullCell,
  query,
  handleFilter,
  uniqueFilter,
  highlight
}) => {
  const [dropdown, setDropdown] = useState(false);
  const cellMarkup = header ? (
    typeof content === "string" &&
    content !== "Actions" &&
    content !== "Contact" ? (
      <CellHeader  dropdown={dropdown} id="clickable" onClick={e => handleSort(content, e)}>
        <div id="clickable" className={content === "Contact" ? "cellDivContact" : "cellDiv"}>
          {content}
          {uniqueFilter && (
            <StyledButton onClick={() => setDropdown(!dropdown)}>
              <FilterList />
            </StyledButton>
          )}
          {dropdown && (
            <DropdownDiv
              handleFilter={handleFilter}
              setDropdown={() => setDropdown(false)}
              values={uniqueFilter}
              header={content}
            />
          )}
          {content === sorter && sortDirection !== null ? (
            sortDirection ? (
              <ArrowDropUp />
            ) : (
              <ArrowDropDown />
            )
          ) : (
            <UnfoldMore />
          )}
        </div>
      </CellHeader>
    ) : (
      <CellHeader >
        <div className="cellDivIcon">{content}</div>
      </CellHeader>
    )
  ) : !nullCell ? (
    <NullCell highlight={highlight === "blue" ? 'lightblue' : highlight==="yellow" ? 'yellow' : false }>{content}</NullCell>
  ) : null;

  return cellMarkup;
};

export default Cell;
