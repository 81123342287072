import React, { useEffect } from "react";
import styled from "styled-components";

const StyledDrop = styled.div`
  transform: translateY(-100px);
  position: absolute;
  background-color: ${(props) => props.theme.midnightBlue};
  border-radius: 5px;
  color: white;
  z-index: 3;
  max-height: 150px;
  overflow-y: scroll;
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ul {
    list-style: none;
    text-align: center;
    padding: 10px;
    display: table;
    margin: 0 auto;
  }
  li {
    font-size: 14px;
    &:hover {
      opacity: 0.6;
    }
  }
`;

const DropdownDiv = ({ values, handleFilter, setDropdown, header }) => {
  //   const [filterValue, setFilterValue] = useState("");
  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => document.removeEventListener("mousedown", handleClick, false);
  }, []);

  const handleClick = (e) => {
    if (e.target.id !== "filterModal") {
      return setDropdown();
    } else {
      return;
    }
  };

  const filterClick = (value) => {
    //   handleFilter(value)
    handleFilter(value, header);
    setDropdown();
  };

  return (
    <StyledDrop>
      <ul>
        <li id="filterModal" onClick={() => filterClick()}>
          No Filter
        </li>
        {values.map((value) => (
          <li id="filterModal" key={value} onClick={() => filterClick(value)}>
            {value}
          </li>
        ))}
      </ul>
    </StyledDrop>
  );
};

export default DropdownDiv;
