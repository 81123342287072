import React, { useState } from "react";
import { Row } from "./styles";
import moment from "moment";
import Cell from "./Cell";

const formatter = (str) => {
  var date = moment(str);
  return date.utc().format("MMMM Do YYYY hh:mma");
};

const RenderSingleRow = ({ currentRow, rowIndex }) => {
  return (
    <Row key={`row=${currentRow}-${rowIndex}`}>
      {currentRow.map((_cell, cellIndex) => {
        if (!_cell) {
          return <Cell key={`${cellIndex}-${rowIndex}`} />;
        }
        return (
          <Cell
            highlight={
              _cell?.includes("Demo Confirmed")
                ? "blue"
                : _cell?.includes("Call to Set")
                ? "yellow"
                : false
            }
            key={`${cellIndex}-${rowIndex}`}
            content={
              _cell.includes("-") && _cell.startsWith("20")
                ? formatter(_cell)
                : _cell
            }
          />
        );
      })}
    </Row>
  );
};
export default RenderSingleRow;
