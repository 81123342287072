import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import API from "../utils/API";

const EmailForm = (props) => {
  const [emailVals, setEmailVals] = useState({
    email: "",
    subject: "",
    body: "",
  });
  const [error, setError] = useState("");

  const inputSetter = (e) =>
    setEmailVals({
      ...emailVals,
      [e.target.name]: e.target.value,
    });

  const clearError = () => {
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const doThing = () => {
    if (!emailVals.email) {
      setError("Please Provide an Email Address to submit");
      clearError();
      return;
    }
    if (!emailVals.subject) {
      setError("Please Provide a subject to submit");
      clearError();
      return;
    }

    if (!emailVals.body) {
      setError("Please Provide a description of your issuse to submit");
      clearError();
      return;
    } else {
      setError("Email Sucessfully Submitted");
      clearError();
      API("sendEmail", {
        from: "callcentersupport@momentumsolar.app",
        to: ["clantier@momentumsolar.com"],
        subject: emailVals.subject,
        body: `${emailVals.body} </br> </br> From, ${emailVals.email}`,
      }).then(
        setEmailVals({
          email: "",
          subject: "",
          body: "",
        })
      );
    }
  };

  return (
    <div className="loginCard fadeInDown form-width">
      <h3>Email Support </h3>
      <TextField
        placeholder="Email"
        value={emailVals.email}
        name="email"
        label="Email Address (Yours)"
        onChange={(e) => inputSetter(e)}
      />
      <TextField
        placeholder="Subject"
        value={emailVals.subject}
        name="subject"
        label="Subject"
        onChange={(e) => inputSetter(e)}
      />
      <TextField
        placeholder="Describe Your Issue"
        value={emailVals.body}
        name="body"
        label="Description of Issue"
        onChange={(e) => inputSetter(e)}
      />

      <button onClick={doThing}> Send </button>
      <h5>{error}</h5>
    </div>
  );
};

export default EmailForm;
