import axios from "axios";

let headers = { "x-api-key": "41fe3626-130a-4c7d-a982-ad6e40175c3c" };

const apiRoute =
  "https://68v6ys4e80.execute-api.us-east-1.amazonaws.com/prod/temp/query";

const apiCalls = {
  demoSetter: (data) =>
    `SELECT * FROM demoSetterLegacyStatic WHERE internalEmailAddress= '${data}' order by demoDate DESC`,
  thirtyData: (data) =>
    `SELECT * FROM demoSetterLegacyStatic WHERE internalEmailAddress= '${data}' order by demoDate DESC`,
  login: (data) => `SELECT * FROM appAccounts where testLogin = '${data}'`,
  sendEmail: (emailPaylod) =>
    axios.post(
      "https://veeyieqt20.execute-api.us-east-1.amazonaws.com/api/sendmailbulk",
      emailPaylod
    ),
};

const API = (queryType, data) => {
  console.log(queryType);
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    return axios.post(
      apiRoute,
      { query: apiCalls[queryType]("ChristianClark@momentumsolar.com") },
      { headers }
    );
  }

  return axios.post(
    apiRoute,
    { query: apiCalls[queryType](data) },
    { headers }
  );
};

export default API;
