import React, { useContext } from "react";
import { AzureAD, LoginType, MsalAuthProviderFactory } from "react-aad-msal";

import { Context } from "../App";
import { withRouter } from "react-router-dom";
import { ExitToApp } from "@material-ui/icons";
import logo from "../assets/logo.png";

// export const history = createHistory();

const AppButtonLaunch = ({ history }) => {
  const { setValue } = useContext(Context);
  const unauthenticatedFunction = (loginFunction) => {
    return (
      <>
        <img src={logo} alt="momentum" />
        <button onClick={() => loginFunction()}>Login</button>
      </>
    );
  };
  const userJustLoggedIn = (receivedUserInfo) => {
    const { name, userName } = receivedUserInfo.account;
    setValue({ name, userName });
    history.push("/dashboard");
  };

  const authenticatedFunction = (logout) => {
    return (
      <ExitToApp
        onClick={() => {
          logout();
          setValue({ name: "", userName: "" });
          history.push("/");
        }}
        className="Button customButton logoutButton"
        style={{ margin: 0 }}
      />
    );
  };
  return (
    <AzureAD
      provider={
        new MsalAuthProviderFactory(
          {
            auth: {
              authority:
                "https://login.microsoftonline.com/e485c427-0599-4dbd-86d9-add2e5942a49",
              clientId: "93761509-eab4-410d-bd95-7f4ceaa20a60",
            },
            cache: {
              storeAuthStateInCookie: true,
            },
          },
          {
            // scopes: ["User.Read"]
            scopes: ["openid"],
          },
          LoginType.Popup
        )
      }
      unauthenticatedFunction={unauthenticatedFunction}
      // reduxStore={basicReduxStore}
      authenticatedFunction={authenticatedFunction}
      accountInfoCallback={userJustLoggedIn}
    />
  );
};

export default withRouter(AppButtonLaunch);
