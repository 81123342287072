import styled, { keyframes } from "styled-components";

const spin = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;

  .loader {
    position: absolute;
    border: 16px solid ${(props) => props.theme.backgroundLightGrey}; /* Light grey */
    border-top: 16px solid ${(props) => props.theme.midnightBlue}; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: ${spin} 2s linear infinite;
    left: ${(props) => (props.sideNavOpen ? "calc(50% + 90px)" : "50%")};
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
  }
`;

export { Loader };
