import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../utils/API';
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';

const Dashboard = ({ history, match, params }) => {
  // const {
  //   userValues: { userName, name },
  //   appointmentData, setAppointmentData
  // } = useContext(Context);

  const [inputVal, setInputVal] = useState();

  // const [appointmentData, setAppointmentData] = useState({
  //   appointmentData: "",
  //   callCenterRepEmail: "",
  //   callCenterRepName: "",
  //   allThirtyData: "",
  //   isQueryMade: true
  // });

  API.salesDataAws('whash@momentumsolar.com').then(res => console.log(res));

  return (
    <FormControl
      fullWidth={true}
      placeholder="Select Department"
      className="dropDown"
    >
      <InputLabel htmlFor="department">Department</InputLabel>
      <Select
        value={inputVal}
        name={'departmentId'}
        id={'department'}
        autoWidth={true}
      >
        {/* {!useDepartments.loading &&
            useDepartments.data.map((department, index) => (
              <MenuItem key={index} value={department.departmentId}>
                {department.departmentName}
              </MenuItem>
            ))} */}
      </Select>
    </FormControl>
  );
};

export default withRouter(Dashboard);
