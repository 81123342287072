import React, { Component } from "react";
//styles
//components
import Cell from "./Cell";
import Dropdown from "../Dropdown/Dropdown";
import TableNav from "./TableNav";
import RowRender from "./RowRender";
import ListView from "./ListView";
// import Exportables from "./Exportables";
//utils
import { tableCalculation, currentRowsCalc } from "../../utils/helpers";
//icons
import { ViewList } from "@material-ui/icons";
import searchIcon from "../../assets/search.png";
import {
  FilterBar,
  Button,
  NullTable,
  TableContainer,
  SideViewDiv,
  Showing,
} from "./styles";
import moment from "moment";

import {
  thisMonthFilter,
  lastMonthFilter,
} from "../../filterUtils/dateFilters";
let formatDate = (date) => moment(date).format("MMMM Do YYYY");

/**@param `Props:
 *    headings: Takes in array of arrays to populate header, i.e. [["Date"], ["Name"], ["Facility]].

 *    rows: Takes in Array of Arrays to populate rows.

 *    rowSearched: Index of row you want to be searched by search bar, i.e. with headers of [["Date"], ["Name"], ["Facility]] a 0 here would result in Date being searched.

 *    callback: Callback function sent down from component holding Table to be fired off on specific updates (rows, search, page, etc.).

 *    table: Name of table (used in callback function), only used to make sure that no callbacks are fired off in transit between components (not sure if necessary still but no performance hit keeping it in for time being).

 *    rating: Niche prop to send down a rating to the GraphQL query for exporting paginated data, i.e. some queries take in a rating for getting only certain data from server.

 *    paginatedExport: If set to true will cause the export button to do a background graphql query for all data as opposed to just paginated data so as to export all onClick.

 *    querySecondary: If using pagination must send the GraphQL query name (on server) here, i.e. "userFetch" to get the data in the background GraphQL call (export data).

 *    queryRowsFunction: If using pagination we send in a "formatting" function to output arrays of data in a clean, succint manner.

 *    queryTitle: We send the imported GraphQL query here, i.e. with the traditional uppercase naming convention might be USER_FETCH.

 *    status: Certain queries require status level for specificity which in turn needs to be sent down the line for paginated export. See Incident Table for example.

 *    facility: takes in the current facility picked in the header (if there is one) to narrow down the export to specific data to that facility.

 *    showResolved: Niche ternary prop, used for tables where data changes onClick (ternary prop).

 *    query: Takes in name of GraphQL query variable, if best practices are happening will match querySecondary prop but could be different.

 *    doubleTable: Niche ternary prop, used to signify that the pagination is actually being doubled (for every 1 item GraphQl sends us we are displaying 2 rows) so we must let the table know not to allow odd numbers of rows because it would throw major bugs (the number 5 will be removed from the "Rows Per Page" dropdown).

 *    handleList: Niche function that calls a ternary "flip" function when the ListView button is clicked to do minor styling changes to the rows rendered in the ListView, see RequiresAttentionTable component for example.` */
class Table extends Component {
  state = {
    tableView: true,
    search: "",
    searchTwo: "",
    sorter: null,
    sortDirection: null,
    iteration: 1,
    currentPage: 1,
    dropdownEl: null,
    dropdownElTwo: null,
    rowsPerPage: 10,
    pageNumbersState: [],
    exportable: "Download All",
    filter: "All Time",
    filterValue: { value: "", header: "" },
    rowSize: "",
  };

  // on all applicable table updates (sort, page, search, results per page) must update the table itself and the callback graphql query.
  // optimized for no unnecessary re-renders - still missing sorting functionality for pagination because of the nature of the lack of adequate nested sorting in laravel's rebing package.
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showResolved !== this.props.showResolved) {
      this.handleCallbackOnUpdate("current");
    }
    if (prevProps.rows !== this.props.rows) {
      this.resetPageNumbers("none");
    }
    if (prevState.filterValue.value !== this.state.filterValue.value) {
      this.resetPageNumbers("current");
    }
    if (prevState.search !== "" && this.state.search === "") {
      this.resetPageNumbers("current");
    }
    if (prevState.search !== this.state.search && this.state.search !== "") {
      this.resetPageNumbers("current");
    }
    if (prevState.searchTwo !== "" && this.state.searchTwo === "") {
      this.resetPageNumbers("current");
    }
    if (
      prevState.searchTwo !== this.state.searchTwo &&
      this.state.searchTwo !== ""
    ) {
      this.resetPageNumbers("current");
    }
    if (
      this.props.callback &&
      prevState.currentPage !== this.state.currentPage
    ) {
      this.handleCallbackOnUpdate("none");
    }
    if (
      this.state.search !== "" &&
      prevState.rowsPerPage !== this.state.rowsPerPage &&
      this.props.callback
    ) {
      this.handleCallbackOnUpdate("current");
    }
    if (
      this.props.callback &&
      prevState.rowsPerPage !== this.state.rowsPerPage
    ) {
      this.handleCallbackOnUpdate("current");
    }
    if (
      this.props.callback &&
      this.state.search !== "" &&
      prevState.rowsPerPage !== this.state.rowsPerPage
    ) {
      this.handleCallbackOnUpdate("current");
    }
  }

  componentDidMount() {
    //when table leaves dom and then re-enters, make sure to refetch query, this
    //circumvents using just network only cache keeping table state proper but also allowing cache data to take precedent when data is already available.
    if (!this.props.callback) {
      this.resetPageNumbers("current");
    }

    this.props.callback && this.handleCallbackOnUpdate("current");
  }

  //handle dropdown button
  handleDropdown = (event) => {
    this.setState({ dropdownEl: event.currentTarget });
  };

  handleDropdownTwo = (event) => {
    this.setState({ dropdownElTwo: event.currentTarget });
  };

  //handle componentDidUpdate if's for when to re-render page numbers, pages per, and re-fetch data with params
  handleCallbackOnUpdate = (current) => {
    //if using the doubleTable prop to use double the amount of queried results per table make sure to not allow for odd number in table
    if (this.props.doubleTable && this.state.rowsPerPage === 5) {
      this.setState({ rowsPerPage: 10 });
    }
    this.props.callback(
      this.state.currentPage,
      this.state.rowsPerPage,
      this.state.search,
      this.state.sortDirection,
      this.state.sorter
    );
    this.resetPageNumbers(current);
  };

  //handle click on table view toggle switch
  tableClick = () => {
    if (this.props.handleList) {
      this.props.handleList();
    }
    this.setState({ tableView: !this.state.tableView });
  };

  // handle input/dropdown changes
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //handle set/close
  handleClose = (item) => {
    item.name
      ? this.setState(
          {
            dropdownEl: null,
            rowsPerPage: item.name,
          },
          () => this.resetPageNumbers("current")
        )
      : this.setState({ dropdownEl: null });
  };

  handleFilter = async (item) => {
    if (item.name) {
      await this.setState({ dropdownElTwo: null, filter: item.name });
      this.resetPageNumbers("current");
    } else {
      this.setState({ dropdownElTwo: null });
    }
  };

  //handle onClick of the number of things wanted per page
  handlePageClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  filterValues = (data) => {
    const { headings, rows } = this.props;
    const {
      filterValue: { value, header },
    } = this.state;
    const headingSort = headings.map((x) => x[0]);
    let filterColumnIndex = headingSort.indexOf(header);
    // console.log(data.filter(a => a[filterColumnIndex] === value).length);

    return data.filter((a) => a[filterColumnIndex] === value);
  };

  filter = (data) => {
    const { filter } = this.state;
    if (filter === "This Month") {
      return thisMonthFilter(data);
    } else if (filter === "Last Month") {
      return lastMonthFilter(data);
    }
  };
  //handle reset of page numbers when mounted and when the rows per page dropdown is updated
  resetPageNumbers = (current) => {
    const { rowsPerPage, filter, filterValue, search, searchTwo } = this.state;
    const { rowSearched } = this.props;
    let { rows } = this.props;
    let pageNumbers = [];
    // let filteredRows;
    if (search && searchTwo) {
      rows = rows.filter((row) => {
        if (typeof row[rowSearched] === "string")
          return (
            row[rowSearched].toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        else if (typeof row[rowSearched] === "number") {
          return (
            row[rowSearched]
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1
          );
        } else {
          return (
            row[rowSearched].props.children
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1
          );
        }
      });
      rows = rows.filter(
        (row) =>
          formatDate(row[1]).toLowerCase().indexOf(searchTwo.toLowerCase()) !==
          -1
      );
    } else if (searchTwo) {
      rows = rows.filter((row) => {
        return (
          formatDate(row[1]).toLowerCase().indexOf(searchTwo.toLowerCase()) !==
          -1
        );
      });
    } else if (search) {
      rows = rows.filter((row) => {
        if (typeof row[rowSearched] === "string")
          return (
            row[rowSearched].toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        else if (typeof row[rowSearched] === "number") {
          return (
            row[rowSearched]
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1
          );
        } else {
          return (
            row[rowSearched].props.children
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1
          );
        }
      });
    }
    // console.log(filterValue);
    if (filterValue.value) {
      rows = this.filterValues(rows);
    }
    if (filter !== "All Time") {
      rows = this.filter(rows);
    }
    if (rows) {
      this.setState({ rowSize: rows.length });
      for (
        let i = 1;
        i <=
        Math.ceil(
          // handles different version of table rows
          //this.props.query is signaling that it is a paginated query so needs to take that into consideration.
          //this.props.query and this.props.doubleTable means that although the fetch is for example 10 pieces of data we're displaying 20 so need to double rowsPerPage and not allow for uneven page numbers
          //(showing 5 per page would break flow because its giving back data divisible by 2);
          rows.length / rowsPerPage
        );
        i++
      ) {
        pageNumbers.push(i);
      }
      //sending this variable current set to "current" causes the table to revert to page 1 (i.e. if you search on page 4 you'd want to see the first results so you'd be sent back to the first page);
      if (current === "current") {
        this.setState({
          pageNumbersState: pageNumbers,
          currentPage: 1,
        });
      } else {
        this.setState({
          pageNumbersState: pageNumbers,
        });
      }
    } else {
      return;
    }
  };

  //handle left and right clicks of the table pagination (with cases for if  on first or last page)
  handleArrowClick = (direction) => {
    const { currentPage, pageNumbersState } = this.state;
    direction === "left" && currentPage === 1
      ? this.setState({
          currentPage: pageNumbersState.length,
        })
      : direction === "left" && currentPage !== 1
      ? this.setState({
          currentPage: currentPage - 1,
        })
      : direction === "right" && currentPage === pageNumbersState.length
      ? this.setState({
          currentPage: pageNumbersState[0],
        })
      : this.setState({
          currentPage: currentPage + 1,
        });
  };

  //handle click for first/last buttons
  handleFirstClick = (direction) => {
    const { pageNumbersState } = this.state;
    direction === "left"
      ? this.setState({ currentPage: 1 })
      : this.setState({ currentPage: pageNumbersState.length });
  };

  //keep search value constrained to 20 characters and if it is paginated revert back to page 1
  updateSearch(value) {
    this.setState({ search: value.substr(0, 20), currentPage: 1 }, () =>
      this.callback ? this.resetPageNumbers("current") : null
    );
  }

  updateSearchTwo(value) {
    this.setState({ searchTwo: value.substr(0, 20), currentPage: 1 }, () =>
      this.callback ? this.resetPageNumbers("current") : null
    );
  }

  //iterate through 3 sort states to control sort and arrow ui
  handleSort = (heading, e) => {
    const { id } = e.target;
    if (id !== "clickable") {
      return false;
    }
    this.state.iteration === 1
      ? this.setState({
          iteration: 2,
          sorter: heading,
          sortDirection: false,
        })
      : this.state.iteration === 2 &&
        this.state.sorter !== heading &&
        this.state.sorter !== null
      ? this.setState({
          iteration: 1,
          sorter: null,
          sortDirection: null,
        })
      : this.state.iteration === 2
      ? this.setState({
          iteration: 3,
          sorter: heading,
          sortDirection: true,
        })
      : this.setState({
          iteration: 1,
          sorter: null,
          sortDirection: null,
        });
    this.resetPageNumbers("current");
  };
  handleFilterData = (value, header) => {
    if (value) {
      this.setState({ filterValue: { value, header } });
    } else {
      this.setState({ filterValue: { value: "", header: "" } });
    }
    // console.log(value);
  };

  //render heading rows

  renderHeadingRow = (_cell, cellIndex) => {
    const { headings, rows } = this.props;
    const filteredArray = rows.reduce((array, row) => {
      array.push(row[cellIndex]);
      return array;
    }, []);
    const unique = [...new Set(filteredArray)];
    const onlyTheseHeaders =
      headings[cellIndex][0] === "Scheduling Status" ||
      headings[cellIndex][0] === "Outcome Status" ||
      headings[cellIndex][0] === "Status" ||
      headings[cellIndex][0] === "Proposal Status";
    return (
      <Cell
        uniqueFilter={
          unique.length < 15 && unique.length > 1 && onlyTheseHeaders && unique
        }
        handleFilter={this.handleFilterData}
        handleSort={this.handleSort}
        sorter={this.state.sorter}
        sortDirection={this.state.sortDirection}
        key={`heading-${cellIndex}`}
        content={headings[cellIndex][0]}
        header={true}
      />
    );
  };

  //handle sort direction
  sortDirection = () => {
    this.setState({ sortDirection: !this.state.sortDirection });
  };

  render() {
    // console.log(this.state.rowSize);
    const {
      dropdownEl,
      dropdownElTwo,
      rowsPerPage,
      currentPage,
      pageNumbersState,
      search,
      sortDirection,
      sorter,
      exportable,
      filter,
      filterValue,
      rowSize,
      searchTwo,
    } = this.state;

    const openDropdown = Boolean(dropdownEl);
    const openDropdownTwo = Boolean(dropdownElTwo);

    const {
      headings,
      rows,
      rowSearched,
      rating,
      paginatedExport,
      querySecondary,
      queryRowsFunction,
      queryTitle,
      status,
      facility,
      showResolved,
      query,
      doubleTable,
      monthFilters,
      secondSearch,
    } = this.props;

    const theadMarkup = (
      <tr key="heading">{headings.map(this.renderHeadingRow)}</tr>
    );
    const headingSort = headings.map((x) => x[0]);
    // filteredRowsCalculation takes in all the sortable, searchable, paginatable functionality and returns the data;
    const filteredRowsCalculation = tableCalculation(
      rows,
      rowSearched,
      search,
      sorter,
      headings,
      headingSort,
      sortDirection,
      query,
      filter,
      filterValue,
      searchTwo
    );

    //currentRowsCalculation takes in the currentRows and spits out the filters for non-paginated data
    //(i.e. all data was received on fetch for small queries not in need of batching);
    const currentRowsCalculation = currentRowsCalc(
      rows,
      currentPage,
      rowsPerPage,
      filteredRowsCalculation,
      query ? query : null
    );

    //searchVariable takes in the current amount of rows (paginated or non) and is used to determine amount of page numbers to render to table.

    const searchVariable = !query
      ? filteredRowsCalculation
        ? filteredRowsCalculation.length
        : null
      : query.total && !doubleTable
      ? query.total
      : query.total * 2;
    return (
      <>
        <FilterBar placeholder={200 + headingSort[rowSearched].length}>
          <div className="searchDiv">
            <img src={searchIcon} alt="searchIcon" className="searchIcon" />
            <input
              type="text"
              className="input"
              placeholder={`Search by ${headingSort[rowSearched]}`}
              value={this.state.search}
              onChange={(evt) => this.updateSearch(evt.target.value)}
            />
          </div>
          {secondSearch && (
            <div className="searchDiv">
              <img src={searchIcon} alt="searchIcon" className="searchIcon" />
              <input
                type="text"
                className="input"
                placeholder={`Search by ${headingSort[1]}`}
                value={this.state.searchTwo}
                onChange={(evt) => this.updateSearchTwo(evt.target.value)}
              />
            </div>
          )}
          {filterValue.value && (
            <div style={{ marginTop: "10px" }}>
              <div style={{ textDecoration: "underline" }}>Current Filter</div>{" "}
              <div>Column: {filterValue.header}</div>{" "}
              <div>Filter: {filterValue.value}</div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              padding: "4px",
              alignItems: "center",
            }}
          >
            <div>Current Rows Count:{rowSize}</div>
          </div>
          <div className="rightFilter">
            {/* {currentRowsCalculation.length > 0 ? ( */}
            <div className="rowsDisplayedDiv">
              {monthFilters && (
                <div className="exportDiv">
                  <Dropdown
                    marginRight={"10px"}
                    width={"150px"}
                    rowValue={this.state.filter}
                    open={openDropdownTwo}
                    handleDropdown={this.handleDropdownTwo}
                    anchorEl={dropdownElTwo}
                    handleClose={this.handleFilter}
                    items={[
                      { id: 1, name: "All Time" },
                      { id: 2, name: "Last Month" },
                      { id: 3, name: "This Month" },
                    ]}
                  />
                </div>
              )}
              <span>Showing</span>
              <Dropdown
                rowValue={rowsPerPage}
                open={openDropdown}
                handleDropdown={this.handleDropdown}
                anchorEl={dropdownEl}
                handleClose={this.handleClose}
                items={
                  !doubleTable
                    ? [
                        { id: 1, name: 5 },
                        { id: 2, name: 10 },
                        { id: 3, name: 20 },
                      ]
                    : [
                        { id: 2, name: 10 },
                        { id: 3, name: 20 },
                      ]
                }
              />
              <span> rows per page</span>
            </div>
            {/* ) : null} */}

            {/* <div className="exportDiv">
              <Exportables
                status={status}
                doubleTable={showResolved}
                paginatedExport={paginatedExport}
                querySecondary={querySecondary}
                facility={facility}
                queryRowsFunction={queryRowsFunction}
                queryTitle={queryTitle}
                limit={query ? query.total : null}
                handleChange={this.handleChange}
                content={exportable}
                rows={
                  exportable === "Download All" ? rows : filteredRowsCalculation
                }
                header={headingSort}
              />
            </div> */}
            <Button type="button" onClick={this.tableClick}>
              <ViewList />
            </Button>
          </div>
        </FilterBar>

        <Showing>
          {currentRowsCalculation && pageNumbersState.length > 1 && (
            <TableNav
              doubleTable={doubleTable}
              search={searchVariable}
              currentPage={currentPage}
              handleFirstClick={this.handleFirstClick}
              handleArrowClick={this.handleArrowClick}
              pageNumbersState={pageNumbersState}
              rows={rows}
              rowsTableOne={rowsPerPage}
              handlePageClick={this.handlePageClick}
            />
          )}
        </Showing>

        {this.state.tableView ? (
          <TableContainer>
            <thead>{theadMarkup}</thead>
            <tbody>
              <RowRender rating={rating} currentRows={currentRowsCalculation} />
            </tbody>
          </TableContainer>
        ) : (
          <SideViewDiv>
            <ListView headings={headings} rows={currentRowsCalculation} />
          </SideViewDiv>
        )}
        {currentRowsCalculation.length === 0 && (
          <NullTable>No Current Data</NullTable>
        )}
        <Showing>
          {currentRowsCalculation && pageNumbersState.length > 1 && (
            <TableNav
              doubleTable={doubleTable}
              search={searchVariable}
              currentPage={currentPage}
              handleFirstClick={this.handleFirstClick}
              handleArrowClick={this.handleArrowClick}
              pageNumbersState={pageNumbersState}
              rows={rows}
              rowsTableOne={rowsPerPage}
              handlePageClick={this.handlePageClick}
            />
          )}
        </Showing>
      </>
    );
  }
}

export default Table;
