import styled from "styled-components";

export const CardNonTab = styled.div`
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  border-top: 1px solid ${(props) => props.theme.backgroundLightGrey};
  border-radius: 8px;
  margin: 30px 0;
  padding: 10px 20px;
  .spanNonTab {
    color: ${(props) => props.theme.midnightBlue};
    padding: 15px 0;
    font-size: 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.backgroundLightGrey};
  }
  .cardTitleIcon {
    margin-right: 10px;
  }
  .tableMargin {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .viewingDiv {
    margin-top: 20px;
    font-size: 24px;
    color: ${(props) => props.theme.slateGrey};
    font-family: ${(props) => props.theme.font};
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding: 4px;
  }
`;
