import React, { useState, useContext } from "react";
import { withRouter } from "react-router";
//styles
import StyledSidebar from "./styles";
//adminlevel
import { Context } from "../../App";
import API from "../../utils/API";
import testAccounts from "../testAccounts";

const SideNav = () => {
  const {
    show,
    userValues: { name, userName },
    setAppointmentData,
  } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const asyncFunc = async () => {
    setLoading(true);

    let accountNameData;
    // We are evaluating one last condition and that is if testAccounts in state includes the same value we set to user... or the login from  reduxStroe => state
    if (testAccounts.includes(userName.toLowerCase())) {
      accountNameData = await API("login", userName);
    }

    const SalesData = await API(
      "demoSetter",
      accountNameData ? accountNameData.data.data[0].testAccount : userName
    );
    console.log(SalesData);

    if (SalesData.data.data.includes("Connection Failed")) {
      console.log("error occurred");
      return false;
    }

    if (!SalesData.data.data.length) {
      console.log("error occurred");
      setAppointmentData({ isQueryMade: false });
      return false;
    }

    const thirtyDataRes = await API(
      "thirtyData",
      accountNameData ? accountNameData.data.data[0].testAccount : userName
    );

    setAppointmentData({
      callCenterRepName: accountNameData
        ? SalesData.data.data[0].demoSetterClean
        : name,
      callCenterRepEmail: accountNameData
        ? SalesData.data.data[0].testAccount
        : userName,
      appointmentData: SalesData.data.data,
      isQueryMade: false,
      allThirtyData: thirtyDataRes.data.data,
    });
    setLoading(false);
  };

  return (
    <>
      <StyledSidebar open={show}>
        <div className="sidenavItems">
          <div className="iconText">
            <div className="adminTextTop">Welcome, {name}</div>
          </div>
        </div>
        <button onClick={() => asyncFunc()}>
          Refresh{loading && "ing"} Data
        </button>
        {/* {testAccounts.includes(userName.toLowerCase()) && 
        <NavList className = 'hidden-in-plain-sight' link="/adminView">
               
                Admin View
              </NavList>
        } */}
      </StyledSidebar>
    </>
  );
};

export default withRouter(SideNav);
